import Vue from "vue";
import Router from "vue-router";

import store from "@/store";
import AuthCoverLayout from "@/views/layout/AuthCoverLayout";
import UnauthenticatedLayout from "@/views/layout/UnauthenticatedLayout";
import AuthenticatedLayout from "@/views/layout/AuthenticatedLayout";
import Login from "@/views/framework/Login";
import Signup from "@/views/framework/Signup";

const Dashboard = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/dashboards/Dashboard.vue"
  );
const Jobs = () =>
  import(/* webpackChunkName: "user-bundle" */ "@/views/operations/Jobs.vue");
const JobDetail = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/JobDetail.vue"
  );
const NewProject = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/NewProject.vue"
  );
const Recovery = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/framework/Recovery.vue"
  );
const Logout = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Logout.vue");
const Reset = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Reset.vue");
const SignupConfirm = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/framework/SignupConfirm.vue"
  );
const Profile = () =>
  import(/* webpackChunkName: "rare-bundle" */ "@/views/framework/Profile.vue");

const Identity = () =>
  import(
    /* webpackChunkName: "rare-bundle" */ "@/views/framework/Identity.vue"
  );

const Plans = () =>
  import(/* webpackChunkName: "user-bundle" */ "@/views/Plans.vue");

const Recharge = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/Recharge.vue"
  );

const BusinessPlan = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/BusinessPlan.vue"
  );

const Transactions = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/Transactions.vue"
  );

const FileManager = () =>
  import(
    /* webpackChunkName: "user-bundle" */ "@/views/operations/FileManager.vue"
  );

Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/signup",
      component: AuthCoverLayout,
      children: [
        {
          path: "/signup",
          name: "Signup",
          component: Signup,
        },
      ],
    },
    {
      path: "/auth",
      component: UnauthenticatedLayout,
      children: [
        {
          path: "/login",
          name: "Login",
          component: Login,
        },
        {
          path: "/recovery",
          name: "Recovery",
          component: Recovery,
        },
        {
          path: "/signupconfirm",
          name: "SignupConfirm",
          component: SignupConfirm,
        },
        {
          path: "/reset",
          name: "Reset",
          component: Reset,
        },
      ],
    },
    {
      path: "/gbl",
      component: AuthenticatedLayout,
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/jobs",
          name: "jobs",
          component: Jobs,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/new-job",
          name: "new-job",
          component: NewProject,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/job/:id",
          name: "job",
          component: JobDetail,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/plans",
          name: "plans",
          component: Plans,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/plans/business",
          name: "business_plan",
          component: BusinessPlan,
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/recharge",
          name: "recharge",
          component: Recharge,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/transactions",
          name: "transactions",
          component: Transactions,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/filemanager",
          name: "filemanager",
          component: FileManager,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/logout",
          name: "logout",
          component: Logout,
          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/profile",
          name: "profile",
          component: Profile,

          meta: {
            requiresAuth: true,
          },
        },
        {
          path: "/identity",
          name: "identity",
          component: Identity,

          meta: {
            requiresAuth: true,
          },
        },
      ],
    },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.path == "/") {
    if (store.state.user.id > 0) {
      next("/dashboard");
    } else next("/signup");
    return;
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (to.meta.requiresAdmin && !store.state.isAdmin()) {
      next("/login");
      return;
    }

    if (to.meta.requiresSuperAdmin && !store.state.isSuperAdmin()) {
      next("/login");
      return;
    }

    if (store.state.user.id > 0) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;

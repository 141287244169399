<template>
  <v-app>
    <v-main>
      <v-overlay :z-index="999999" :value="$store.state.global_overlay">
        <v-row
          ><v-col
            ><v-alert
              style="background-color: #2d3a5e"
              class="alert-notify"
              color="#bf126c"
              border="left"
              elevation="2"
              colored-border
            >
              <span class="text-center text-sm"
                >{{ $store.state.global_overlay_text
                }}<v-progress-circular
                  :indeterminate="true"
                  :rotate="0"
                  :size="24"
                  :width="3"
                  color="#bf126c"
                  class="ml-4"
                ></v-progress-circular>
                <v-progress-linear
                  v-if="$store.state.global_overlay_progress == 0"
                  class="mt-2"
                  indeterminate
                  color="yellow darken-2"
                ></v-progress-linear>
                <v-progress-linear
                  v-if="$store.state.global_overlay_progress > 0"
                  class="mt-2"
                  :value="$store.state.global_overlay_progress * 100"
                  color="yellow darken-2"
                ></v-progress-linear></span></v-alert></v-col
        ></v-row>
      </v-overlay>

      <drawer
        :drawer="drawer"
        :sidebarColor="sidebarColor"
        :sidebarTheme="sidebarTheme"
      >
      </drawer>
      <div
        @click="drawer = false"
        v-if="drawer"
        class="position-absolute drawer-state"
      ></div>
      <AppBar
        v-if="$route.name != 'Profile'"
        background="bg-transparent"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
        :navbarFixed="navbarFixed"
      ></AppBar>
      <AppBar
        v-else-if="$route.name == 'Profile'"
        background="bg-default"
        has-bg
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></AppBar>
      <AppBar
        v-else
        background="primary"
        linkColor="rgba(0,0,0,.6)"
        @drawer-toggle="drawer = $event"
        :toggle-active="drawer"
      ></AppBar>
      <div style="height: 100px" />
      <fade-transition :duration="200" origin="center top" mode="out-in">
        <!-- your content here -->
        <router-view></router-view>
      </fade-transition>

      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </v-main>
  </v-app>
</template>
<script>
// /* eslint-disable no-new */
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

import { FadeTransition } from "vue2-transitions";
import Drawer from "@/components/mockup/Drawer.vue";
import AppBar from "@/components/mockup/AppBar";
import ContentFooter from "@/components/mockup/Footer.vue";

export default {
  components: {
    ContentFooter,
    FadeTransition,
    Drawer,
    AppBar,
  },
  data() {
    return {
      drawer: null,
      sidebarColor: "primary",
      sidebarTheme: "transparent",
      navbarFixed: false,
    };
  },
  methods: {
    initScrollbar() {
      let isWindows = navigator.platform.startsWith("Win");
      if (isWindows) {
        initScrollbar("sidenav");
      }
    },

    updateSidebarColor(value) {
      this.sidebarColor = value;
    },
    updateSidebarTheme(value) {
      this.sidebarTheme = value;
    },
    toggleNavbarPosition(value) {
      this.navbarFixed = value;
    },
  },
  mounted() {
    this.$vuetify.lang.current = this.$store.state.user.locale;
    this.initScrollbar();
  },
};
</script>
<style lang="scss"></style>

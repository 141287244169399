<template>
  <span>
    <template>
      <v-footer color="transparent" class="mt-10 pt-10 pb-5">
        <v-card class="flex" color="transparent">
          <v-card-text class="px-0">
            <v-row>
              <v-col cols="12" lg="8" class="mb-2 mx-auto text-center">
                <a
                  v-for="item in footer"
                  :key="item.linkName"
                  :href="item.link"
                  class="text-decoration-none text-secondary ls-0 mx-4 text-md"
                  target="_blank"
                  >{{ item.linkName }}</a
                >
              </v-col>
              <v-col cols="8" class="mx-auto text-center">
                <v-row align="center" justify="center">
                  <div :key="item.icon" v-for="item in icons">
                    <v-col v-if="item.href">
                      <v-btn
                        :key="item.icon"
                        icon
                        width="31"
                        :ripple="false"
                        class="mx-3 text-secondary no-default-hover"
                        link
                        :href="item.href"
                        target="_blank"
                      >
                        <v-icon size="18">{{ item.icon }}</v-icon>
                      </v-btn>
                    </v-col>
                  </div>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="8" class="mx-auto text-center">
                <p class="copyright text-secondary text-md ls-0">
                  Copyright © 2021 - {{ new Date().getFullYear() }}
                  by Renderglitch
                </p>
                <p></p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-footer>
    </template>
  </span>
</template>

<script>
export default {
  name: "shared-footer",

  data() {
    return {
      footer: [
        {
          linkName:
            "About " + this.$store.state.identity
              ? this.$store.state.identity.name
              : "",
          link: "",
        },

        {
          linkName: "License",
          link: "https://www.render-glitch.com/license",
        },
      ],
      icons: [
        {
          icon: "fab fa-facebook",
          href:
            this.$store.state.identity &&
            this.$store.state.identity[
              this.$store.state.user.locale + ".facebook"
            ]
              ? this.$store.state.identity[
                  this.$store.state.user.locale + ".facebook"
                ]
              : null,
        },
        {
          icon: "fab fa-twitter",
          href:
            this.$store.state.identity &&
            this.$store.state.identity[
              this.$store.state.user.locale + ".twitter"
            ]
              ? this.$store.state.identity[
                  this.$store.state.user.locale + ".twitter"
                ]
              : null,
        },
        {
          icon: "fab fa-instagram",
          href:
            this.$store.state.identity &&
            this.$store.state.identity[
              this.$store.state.user.locale + ".instagram"
            ]
              ? this.$store.state.identity[
                  this.$store.state.user.locale + ".instagram"
                ]
              : null,
        },
        {
          icon: "fab fa-linkedin",
          href:
            this.$store.state.identity &&
            this.$store.state.identity[
              this.$store.state.user.locale + ".linkedin"
            ]
              ? this.$store.state.identity[
                  this.$store.state.user.locale + ".linkedin"
                ]
              : null,
        },
      ],
    };
  },
};
</script>

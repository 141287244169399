<template>
  <v-app class="bg-white">
    <v-container v-if="context_loaded" class="position-sticky top-0 z-index-2">
      <v-row>
        <v-col cols="12">
          <app-bar-blur-auth> </app-bar-blur-auth>
        </v-col>
      </v-row>
    </v-container>
    <v-main class="auth-pages">
      <v-container v-if="!context_loaded" fluid fill-height>
        <v-layout class="align-center justify-center">
          <v-progress-circular
            :indeterminate="true"
            :rotate="20"
            :size="128"
            :width="10"
            color="#3A416F"
          ></v-progress-circular>
        </v-layout>
      </v-container>

      <div
        v-if="context_loaded"
        class="header-auth position-relative pb-16 pt-16 border-radius-xl min-vh-75"
      >
        <v-container>
          <v-row>
            <v-col lg="4" md="6" cols="12" class="d-flex flex-column mx-auto">
              <fade-transition
                :duration="200"
                origin="center top"
                mode="out-in"
              >
                <!-- your content here -->
                <router-view></router-view>
              </fade-transition>
            </v-col>
            <v-col md="6">
              <div
                class="oblique position-absolute top-0 h-100 d-md-block d-none me-n8"
              >
                <div
                  class="oblique-image position-absolute fixed-top ms-auto h-100 w-100 z-index-0 ms-n16"
                  :style="`background-image: url(${require('@/../../shared/app/assets/img/login_background.png')}`"
                ></div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <unauth-content-footer
        auth
        v-if="!$route.meta.hideFooter"
      ></unauth-content-footer>
    </v-main>
  </v-app>
</template>
<script>
import AppBarBlurAuth from "@/components/mockup/AppBarBlurAuth";
import { FadeTransition } from "vue2-transitions";
import UnauthContentFooter from "@/components/mockup/UnauthFooter.vue";

import context from "@/apis/context";

export default {
  name: "page-layout",
  components: {
    AppBarBlurAuth,
    FadeTransition,
    UnauthContentFooter,
  },
  data() {
    return {
      context_loaded: false,
    };
  },

  mounted() {
    this.context_loaded = false;
    context
      .fetchContext()
      .then((res) => {
        this.context_loaded = true;
        this.$store.state.storeContextAndIdentity(res.context, res.identity);
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>

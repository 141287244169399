<template>
  <v-app-bar
    height="auto"
    class="blur blur-rounded shadow top-0 position-absolute my-3 py-2"
  >
    <v-container class="py-0">
      <v-row>
        <v-col cols="12" md="2" class="d-flex align-center">
          <p class="font-weight-bolder ms-lg-0 ms-4 mb-0 text-typo text-sm">
            <a href="https://www.render-glitch.com">
              <v-img
                class="mx-auto"
                max-width="256"
                max-height="256"
                :src="require('@/../../shared/app/assets/logo.png')"
            /></a>
          </p>
        </v-col>

        <v-col cols="12" md="8" class="mx-auto text-center">
          <v-btn
            v-for="item in links"
            :key="item.id"
            :ripple="false"
            color="transparent"
            :class="{ 'btn-dark-hover': !hasBg, 'btn-hover': hasBg }"
            class="text-typo text-capitalize ls-0 align-center"
            depressed
            link
            :to="item.link"
            :href="item.href"
          >
            <v-icon>{{ item.icon }}</v-icon>
            {{ item.name }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>
<script>
export default {
  name: "app-bar-blur",
  components: {},
  props: {
    background: String,
    hasBg: Boolean,
    linkColor: String,
  },
  data() {
    return {
      dialog: false,
      links: [
        {
          name: this.$t("topbar-pricing"),
          href: "https://render-glitch.com/pricing-renderfarm/",
          id: 1,
        },
        {
          name: this.$t("topbar-technology"),
          href: "https://render-glitch.com/distributed-rendering/",
          id: 2,
        },
        {
          name: this.$t("topbar-contact-us"),
          href: "https://render-glitch.com/contact-us-enterprise/",
          id: 3,
        },
        {
          name: this.$t("topbar-cost-signin"),
          icon: "mdi-login",
          link: "/login",
          id: 4,
        },
        {
          name: this.$t("topbar-cost-signup"),
          icon: "mdi-account-circle-outline",
          link: "/signup",
          id: 5,
        },
      ],
    };
  },
};
</script>

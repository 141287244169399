<template>
  <div>
    <v-card v-if="!signed_up" class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
      <div class="card-padding pb-0">
        <h3 class="text-h3 font-weight-bolder text-primary text-red mb-2">
          {{ $t("sign-up-join-us") }}
        </h3>
        <h5 class="mb-0 text-body">{{ $t("sign-up-join-us-details") }}</h5>
        <h5 class="mt-10 mb-2 text-h5 font-weight-bold text-typo">
          {{ $t("profile-password-requirements-title") }}
        </h5>
        <p class="text-muted mb-2">
          {{ $t("profile-password-requirements-subtitle") }}
        </p>
        <div class="d-sm-flex">
          <ul class="text-muted ps-6 mb-0">
            <li>
              <span class="text-sm">{{
                $t("profile-password-requirements-1")
              }}</span>
            </li>
            <li>
              <span class="text-sm">{{
                $t("profile-password-requirements-2")
              }}</span>
            </li>
            <li>
              <span class="text-sm">{{
                $t("profile-password-requirements-3")
              }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-padding pb-4">
        <form>
          <ValidationObserver ref="obs" v-slot="{ invalid }">
            <label class="text-xs text-typo font-weight-bolder ms-1">{{
              $t("sign-up-first-name")
            }}</label>
            <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <FormTextInput
                v-model="first_name"
                :error-messages="errors"
                :success="valid"
                :disabled="signing_up"
              >
              </FormTextInput>
            </ValidationProvider>

            <label class="text-xs text-typo font-weight-bolder ms-1">{{
              $t("sign-up-last-name")
            }}</label>
            <ValidationProvider rules="required" v-slot="{ errors, valid }">
              <FormTextInput
                v-model="last_name"
                :error-messages="errors"
                :success="valid"
                :disabled="signing_up"
              >
              </FormTextInput>
            </ValidationProvider>

            <label class="text-xs text-typo font-weight-bolder ms-1">{{
              $t("sign-up-email")
            }}</label>
            <ValidationProvider
              rules="required|email"
              v-slot="{ errors, valid }"
            >
              <FormTextInput
                v-model="email"
                :error-messages="errors"
                :success="valid"
                :disabled="signing_up"
              >
              </FormTextInput>
            </ValidationProvider>

            <ValidationProvider
              rules="required|strong_password"
              v-slot="{ errors, valid }"
              vid="password_confirm"
            >
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("sign-up-password")
              }}</label>
              <FormTextInput
                @focus="firstFieldReset('view_password')"
                @blur="firstFieldReset('view_password')"
                v-model="password"
                :type="view_password ? 'password' : 'text'"
                :success="valid"
                :disabled="signing_up"
                required
                hide-details
                :placeholder="$t('reset-pwd-new-password')"
              >
                <template v-slot:append>
                  <v-btn @click="() => (view_password = !view_password)" icon>
                    <v-icon size="16">
                      {{ view_password ? "fa-eye" : "fa-eye-slash" }}
                    </v-icon>
                  </v-btn>
                </template>
              </FormTextInput>
              <p
                class="text-left text-red text-caption ml-2 mb-0 mt-0"
                v-if="errors[0]"
              >
                {{ errors[0] ? errors[0] : "" }}
              </p>
            </ValidationProvider>
            <div class="mt-2 mb-2" />
            <ValidationProvider
              rules="required|confirmed:password_confirm"
              v-slot="{ errors, valid }"
            >
              <label class="text-xs text-typo font-weight-bolder ms-1">{{
                $t("sign-up-confirm-password")
              }}</label>
              <FormTextInput
                :type="view_password_confirm ? 'password' : 'text'"
                @focus="firstFieldReset('view_password_confirm')"
                @blur="firstFieldReset('view_password_confirm')"
                :success="valid"
                :disabled="signing_up"
                v-model="repeat_password"
                required
                hide-details
                height="40"
                :placeholder="$t('reset-pwd-repeat')"
              >
                <template v-slot:append>
                  <v-btn
                    @click="
                      () => (view_password_confirm = !view_password_confirm)
                    "
                    icon
                  >
                    <v-icon size="16">
                      {{ view_password_confirm ? "fa-eye" : "fa-eye-slash" }}
                    </v-icon>
                  </v-btn>
                </template>
              </FormTextInput>
              <p
                class="text-left text-red text-caption ml-2 mb-0 mt-0"
                v-if="errors[0]"
              >
                {{ errors[0] ? errors[0] : "" }}
              </p>
            </ValidationProvider>
            <v-checkbox
              v-model="term_and_conditions"
              :disabled="signing_up"
              color="#141727"
              :ripple="false"
              class="ma-0 checkbox-custom checkbox-thinner"
              hide-details
            >
              <template v-slot:label>
                <span class="text-typo text-body-2 ls-0"
                  >{{ $t("sign-up-agree") }}
                  <a
                    href="/terms-and-conditions"
                    class="text-dark font-weight-bolder text-decoration-none"
                    >{{ $t("sign-up-terms-and-conditions") }}</a
                  ></span
                >
              </template>
            </v-checkbox>

            <v-col class="mt-4" align="center">
              <p class="mb-0 text-sm text-red" v-if="signup_error">
                {{ signup_error }}
              </p>
              <UniversalButton
                @click="signUp()"
                v-if="!signing_up"
                :disabled="!term_and_conditions || invalid"
                class="w-100"
                >{{ $t("sign-up-action") }}</UniversalButton
              >
              <v-progress-circular
                v-if="signing_up && !signed_up"
                class="ml-2"
                :indeterminate="true"
                :rotate="0"
                :size="32"
                :width="4"
                color="#3A416F"
              ></v-progress-circular>
            </v-col>

            <div v-if="!signing_up" class="text-center">
              <p class="text-sm text-body mt-3 mb-0">
                {{ $t("sign-up-already-have-account") }}

                <a
                  href="/login"
                  class="text-primary text-red text-decoration-none font-weight-bold"
                  >{{ $t("sign-up-sign-in") }}</a
                >
              </p>
            </div>
          </ValidationObserver>
        </form>
      </div>
    </v-card>
    <v-card v-if="signed_up" class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
      <div class="card-padding pb-0">
        <div>
          <h3 class="text-h3 font-weight-bolder text-primary text-red mb-2">
            {{ $t("sign-up-thank-you") }}
          </h3>
          <h5 class="mb-0 text-body">
            {{ $t("sign-up-thank-you-confirm") }}
          </h5>
        </div>
      </div>
    </v-card>
  </div>
</template>
<script>
import context from "@/apis/context";
import usersManager from "@/apis/users";

export default {
  name: "sign-up-cover",
  data() {
    return {
      term_and_conditions: false,
      context_loaded: false,
      view_password_confirm: false,
      view_password: false,
      signed_up: false,
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      repeat_password: null,
      signing_up: false,
      signup_error: null,
    };
  },

  mounted() {
    this.context_loaded = false;
    context
      .fetchContext()
      .then((res) => {
        this.context_loaded = true;
        this.$store.state.storeContextAndIdentity(res.context, res.identity);
      })
      .catch((err) => {
        console.log(err);
      });
  },

  methods: {
    firstFieldReset: function (name) {
      if (!this["reset_field_" + name]) {
        this[name] = true;
        this["reset_field_" + name] = true;
      }
    },

    signUp() {
      let ctx = {
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password,
        locale: this.$store.state.user.locale,
      };
      this.signing_up = true;
      usersManager
        .signUp(ctx)
        .then((result) => {
          this.signing_up = false;
          if (result.err) this.signup_error = result.err;
          else this.signed_up = true;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<template>
  <v-container>
    <v-row class="justify-center d-flex mt-n16">
      <v-col lg="4" md="7" class="mx-auto">
        <v-card class="card-shadow border-radius-xl">
          <div class="card-padding text-center"></div>

          <div class="mt-2 position-relative text-center">
            <p
              class="text-sm font-weight-bold mb-2 text-secondary short-text-border text-border d-inline z-index-2 bg-white px-3"
            >
              {{ $t("login-subtitle") }}
            </p>
          </div>
          <v-form @submit.prevent="login(false)" id="login-form">
            <ValidationObserver immediate ref="obs" v-slot="{ invalid }">
              <form>
                <div class="card-padding">
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <FormTextInput
                          v-on:keyup.enter="login(false)"
                          :disabled="logging"
                          v-model="email"
                          :error-messages="errors"
                          :success="valid"
                        >
                        </FormTextInput>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col>
                      <ValidationProvider
                        rules="required"
                        v-slot="{ errors, valid }"
                      >
                        <FormTextInput
                          v-on:keyup.enter="login(false)"
                          :disabled="logging"
                          outlined
                          v-model="password"
                          :error-messages="errors"
                          :success="valid"
                          autocomplete="off"
                          :append-icon="
                            password_view ? 'mdi-eye' : 'mdi-eye-off'
                          "
                          @click:append="() => (password_view = !password_view)"
                          :type="password_view ? 'password' : 'text'"
                        >
                        </FormTextInput>
                      </ValidationProvider>
                    </v-col>
                  </v-row>
                  <div class="text-center">
                    <UniversalButton
                      :disabled="invalid"
                      v-if="!logging"
                      @click="login(false)"
                    >
                      {{ $t("login-button") }}
                    </UniversalButton>
                  </div>
                  <p
                    v-if="logging"
                    class="text-center text-sm text-body mb-0 mt-2"
                  >
                    <v-progress-circular
                      class="mb-2"
                      :indeterminate="true"
                      :rotate="0"
                      :size="32"
                      :width="4"
                      color="#3A416F"
                    ></v-progress-circular>
                  </p>

                  <div class="mt-2" v-if="loginError">
                    <v-alert
                      close-icon="fas fa-times font-size-root text-white"
                      class="alert alert-danger"
                      type="error"
                      dense
                      dismissible
                    >
                      <span class="text-uppercase" v-text="loginError" />
                    </v-alert>
                  </div>

                  <p
                    v-if="!logging"
                    class="text-center text-sm text-body mt-3 mb-0"
                  >
                    {{ $t("login-lost-password") }}<br />

                    <router-link
                      class="text-dark text-decoration-none font-weight-bolder"
                      to="/recovery"
                    >
                      {{ $t("login-lost-password-link") }}
                    </router-link>
                  </p>
                  <p
                    v-if="!logging"
                    class="text-center text-sm text-body mt-3 mb-0"
                  >
                    {{ $t("login-signin") }}<br />

                    <router-link
                      class="text-dark text-decoration-none font-weight-bolder"
                      to="/signup"
                    >
                      {{ $t("login-signin-link") }}
                    </router-link>
                  </p>
                </div>
              </form>
            </ValidationObserver>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import vee from "@/plugins/vee-validate.js";
import staticData from "@/apis/static";

export default {
  data() {
    return {
      logging: false,
      email: "",
      password: "",
      loginError: "",
      password_view: true,
    };
  },

  mounted() {
    this.logging = false;
    this.$refs.obs.reset();
    window.login = this.$options.methods.login.bind(this);
  },

  computed: {},

  methods: {
    login: function () {
      let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let email = this.email;
      let password = this.password;

      this.loginError = "";
      this.logging = true;
      this.$store
        .dispatch("login", {
          email,
          password,
          timezone,
        })
        .then(() => {
          this.$store
            .dispatch("fetchStaticData")
            .then(() => {
              this.$i18n.locale = this.$store.state.user.locale;
              vee.setupLocale(); // Required to regenerate the locales in the custom messages
              staticData.update();
              this.$router.push("/dashboard");
            })
            .catch((err) => {
              this.loginError = err;
              this.logging = false;
            });
        })
        .catch((err) => {
          this.loginError = err;
          this.logging = false;
        });
    },
  },
};
</script>

<style>
.otp-code-field {
  max-width: 100px;
  margin: auto;
  margin-top: 10px;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
}
</style>
